import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Button, Flex } from 'antd';
import styled from 'styled-components';

const CONTENT_WIDTHS = {
  full: {
    xs: { span: 24, offset: 0 },
    xl: { span: 24, offset: 0 },
  },
  normal: {
    xs: { span: 24, offset: 0 },
    lg: { span: 18, offset: 3 },
    xl: { span: 16, offset: 4 },
  },
  thin: {
    xs: { span: 24, offset: 0 },
    lg: { span: 14, offset: 5 },
    xl: { span: 10, offset: 7 },
  },
};

export const generateColumnWidths = (
  contentWidth: 'full' | 'normal' | 'thin'
) => {
  let result;
  switch (contentWidth) {
    case 'full':
      result = CONTENT_WIDTHS.full;
      break;
    case 'normal':
      result = CONTENT_WIDTHS.normal;
      break;
    case 'thin':
      result = CONTENT_WIDTHS.thin;
      break;
    default:
      break;
  }
  return result;
};

const Wrapper = styled.div`
  .page-header {
    padding: 16px 24px;
    border: 1px solid ${(props) => props.theme.colors['border-color']};
    border-radius: 6px;
    margin-bottom: 24px;
    .page-header-back {
      margin-top: -2px;
    }
    .page-header-title {
      font-weight: 600;
      font-size: 16px;
    }
    .page-header-subtitle {
      margin-top: 4px;
      color: #595959;
      font-size: 14px;
    }
  }
`;

export type ContentProps = {
  contentWidth?: 'full' | 'normal' | 'thin';
  title?: string;
  onBack?: () => void;
  subTitle?: string;
  children: React.ReactNode;
};

const Content = ({
  contentWidth = 'full',
  title = '',
  // @ts-ignore
  onBack = null,
  subTitle = '',
  children,
}: ContentProps) => (
  <Wrapper>
    <Row>
      {/* @ts-ignore */}
      <Col {...generateColumnWidths(contentWidth)}>
        {title && (
          <div className="page-header">
            <Flex gap="middle">
              {onBack && (
                <div className="page-header-back">
                  <Button
                    type="text"
                    size="small"
                    icon={<ArrowLeftOutlined />}
                    onClick={onBack}
                  />
                </div>
              )}
              <div>
                <div className="page-header-title">{title}</div>
                {subTitle && (
                  <div className="page-header-subtitle">{subTitle}</div>
                )}
              </div>
            </Flex>
          </div>
        )}
        <div>{children}</div>
      </Col>
    </Row>
  </Wrapper>
);

export default Content;
