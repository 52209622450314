import { Button } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AccountMenu from './AccountMenu/AccountMenu';
import CompaniesMenu from './CompaniesMenu/CompaniesMenuContainer';
import Logo from './Logo';
import MainMenu from '../DashboardMenu/MainMenu';

const Wrapper = styled.div``;

const Header = styled.header`
  height: 54px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  background: #fafafa;
  @media (max-width: 992px) {
    padding: 0 16px;
  }
  .dashboard-menu-mobile {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0px 0px 12px;
    }
  }
  .account-menu {
    display: block;
    @media (max-width: 992px) {
      display: none;
    }
  }
`;

const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
`;

const AppBar = () => {
  const user = useSelector((state: any) => state.user);
  const { firstName, lastName } = user;

  return (
    <Wrapper>
      <Header>
        <Logo />
        {user?.token && <CompaniesMenu />}
        <HeaderRight>
          {user?.token ? (
            <>
              <span className="account-menu">
                <AccountMenu firstName={firstName} lastName={lastName} />
              </span>
              <div className="dashboard-menu-mobile">
                <MainMenu mode="mobile" />
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <Button href="/" type="primary">
                Go to Govchain
              </Button>
            </div>
          )}
        </HeaderRight>
      </Header>
    </Wrapper>
  );
};

export default AppBar;
