const roles = ['none', 'starter', 'pro', 'pro_vat', 'family'];
export type accessLevelRoleTypes =
  | 'none'
  | 'starter'
  | 'pro'
  | 'pro_vat'
  | 'family';
// currentCompany object from state
// minRole: Minimum role to test for

export const canAccess = (
  currentCompany: any,
  minRole?: accessLevelRoleTypes
) => {
  if (currentCompany?.isBooksEnabled || !minRole) {
    return true;
  }

  if (minRole) {
    const accessRank = roles.indexOf(currentCompany.accessLevel);
    const minRank = roles.indexOf(minRole);

    return accessRank >= minRank;
  }

  return false;
};
