import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AccountModal from './AccountModal';
import { logoutUser } from 'common/lib/user';

const Wrapper = styled.div`
  padding: 0px 12px;
  margin-right: -12px;
  height: 54px;
  display: flex;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  font-size: 13px;
`;

const LogoutButton = styled.span`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    margin-right: 5px;
    @media (max-width: 992px) {
      margin-right: 0px;
    }
  }

  > i {
    margin-left: 5px;
  }
`;

const Username = styled.span`
  @media (max-width: 992px) {
    display: none;
  }
`;

interface AccountMenuProps {
  firstName: string;
  lastName: string;
}

export const AccountMenu = ({ firstName, lastName }: AccountMenuProps) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { email } = useSelector((state: any) => state.user);

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case 'login':
        history.push('/login');
        break;
      case 'profile':
        history.push('/account/profile');
        break;
      case 'billing':
        history.push('/account/billing');
        break;
      case 'plans':
        history.push('/subscriptions');
        break;
      case 'orders':
        history.push('/account/orders');
        break;
      case 'logout':
        logoutUser();
        break;
      default:
        break;
    }
  };

  if (!email && !firstName && !lastName) {
    return (
      <Wrapper className="flex items-center justify-center">
        <Button href={process.env.REACT_APP_DOMAIN} type="primary">
          Go to Govchain
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <LogoutButton onClick={() => setOpen(true)}>
        <Avatar size="small">{`${firstName[0]}${lastName[0]}`}</Avatar>
        <Username>
          {email} <CaretDownOutlined />
        </Username>
      </LogoutButton>
      <AccountModal
        open={open}
        onCancel={() => setOpen(false)}
        onMenuClick={handleMenuClick}
      />
    </Wrapper>
  );
};

export default AccountMenu;
