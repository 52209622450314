import { isTaskDueSoon } from './isTaskDueSoon';
import { isTaskOverdue } from './isTaskOverdue';
import { ComplianceTaskApiType, ComplianceTaskType } from '../types';

/**
 * Enriches an array of compliance tasks with a due status.
 * @param {ComplianceTaskApiType[]} tasks - Array of tasks to be enriched.
 * @returns {ComplianceTaskType[]} - Array of enriched tasks with due status.
 */
export const enrichComplianceTasks = (
  tasks: ComplianceTaskApiType[] // Array of tasks to be enriched
): ComplianceTaskType[] => {
  return tasks.map((task) => ({
    ...task, // Spread existing task properties
    dueStatus: (() => {
      if (isTaskOverdue(task)) return 'overdue'; // Check if task is overdue
      if (isTaskDueSoon(task)) return 'dueSoon'; // Check if task is due soon
      return 'default'; // Default status if neither overdue nor due soon
    })(),
  }));
};
