import { MailOutlined, MessageOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import AfricaImage from './africa.png';

import { analytics } from 'common/analytics/analytics';
import {
  EMAIL_BUTTON_CLICKED,
  LIVE_CHAT_BUTTON_CLICKED,
} from 'common/analytics/events';

const PROMOURL = process.env.REACT_APP_PROMO_URL;

const Foot = () => {
  const currentYear = format(new Date(), 'yyyy');
  const handleEmailLink = () => {
    analytics.track(EMAIL_BUTTON_CLICKED, {
      category: 'header',
    });
    window.open('mailto:support@govchain.co.za');
  };

  const handleLiveChatLink = () => {
    analytics.track(LIVE_CHAT_BUTTON_CLICKED, {
      category: 'header',
    });
    window.Intercom('show');
  };
  return (
    <div className="border-t border-gray-200 bg-zinc-50 px-[60px] py-[24px] text-sm text-gray-700">
      <div className="space-y-4">
        <div className="flex flex-col justify-between space-y-4 sm:flex-row sm:space-y-0">
          <div>
            <ul className="flex flex-col space-y-2 text-center sm:flex-row sm:space-x-4 sm:space-y-0 sm:text-left">
              <li>
                <a
                  onClick={() => handleEmailLink()}
                  onKeyDown={(e) => e.key === 'Enter' && handleEmailLink()}
                  role="button"
                  tabIndex={0}
                >
                  <MailOutlined />
                  <span className="ml-2">support@govchain.co.za</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => handleLiveChatLink()}
                  onKeyDown={(e) => e.key === 'Enter' && handleLiveChatLink()}
                  role="button"
                  tabIndex={0}
                >
                  <MessageOutlined />
                  <span className="ml-2">Start a live chat</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex justify-center">
            <ul className="flex flex-row space-x-4">
              <li>
                <a target="blank" href="https://help.govchain.co.za/">
                  Support
                </a>
              </li>
              <li>
                <a href={`${PROMOURL}legal`}>Legal</a>
              </li>
              <li>
                <a href={`${PROMOURL}contact`}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center leading-relaxed sm:text-left sm:leading-normal">
          Govchain (Pty) Ltd (registration number 2017/257825/07) is registered
          in South Africa. Our business address is 50 Long Street, Cape Town,
          South Africa, 8000.
        </div>
        <div className="flex flex-col justify-between space-y-2 sm:flex-row sm:space-y-0">
          <div className="text-center sm:text-left">
            © {currentYear} Govchain (Pty) Ltd
          </div>
          <div>
            <div className="flex items-center justify-center sm:justify-end">
              {/* eslint-disable react/jsx-one-expression-per-line */}
              Made in Africa{' '}
              <img src={AfricaImage} alt="africa" className="ml-2 h-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foot;
