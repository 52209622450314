import { Typography, Card, List } from 'antd';
import styled from 'styled-components';
import InvoiceViewItemsListItem from './InvoiceViewItemsListItem';
import { Item } from 'modules/accounting/Invoices/types';
import { formatCurrency } from 'modules/accounting/Receipts/components/MatchReceipts/utils';

const { Text } = Typography;

const ItemsCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
    display: flex;
    flex-direction: column;

    & .with-border {
      border-top: 2px solid #f0f0f0;
      padding-top: 12px;
    }

    & .invoice-items {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title-bar {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: 'item item item quantity quantity price amount amount';
        .item {
          grid-area: item;
        }
        .quantity {
          grid-area: quantity;
        }
        .price {
          grid-area: price;
          justify-self: end;
        }
        .amount {
          grid-area: amount;
          justify-self: end;
        }
      }

      & .line-item-total {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: auto;
        gap: 8px;
        justify-content: end;
        grid-template-areas: '... ... ... ... heading heading total total';

        @media (max-width: 576px) {
          grid-template-columns: repeat(5, 1fr);
          grid-auto-rows: auto;
          grid-template-areas: '... heading heading total total';
        }

        & .total {
          grid-area: total;
          justify-self: end;
        }

        & .heading {
          grid-area: heading;
          justify-self: end;
        }
      }

      @media (max-width: 576px) {
        .title-bar {
          display: none;
        }
      }
    }
  }
`;

type InvoiceViewItemsListProps = {
  lineItems?: Item[];
  subTotal?: number;
  totalVat?: number;
  totalIncludingVat?: number;
};

const InvoiceViewItemsList = ({
  lineItems,
  subTotal,
  totalVat,
  totalIncludingVat,
}: InvoiceViewItemsListProps) => {
  return (
    <ItemsCard>
      <div className="text-lg font-semibold text-gray-500">Items:</div>

      <div className="invoice-items">
        <div className="line-item title-bar">
          <Text strong className="item title text-gray-700">
            Item
          </Text>
          <Text strong className="quantity title text-gray-700">
            Quantity
          </Text>
          <Text strong className="price title text-gray-700">
            Price
          </Text>
          <Text strong className="amount title text-gray-700">
            Amount
          </Text>
        </div>

        <List
          itemLayout="vertical"
          dataSource={lineItems}
          renderItem={(item, index) => (
            <InvoiceViewItemsListItem item={item} index={index} />
          )}
        />

        {!!subTotal && !!totalVat && (
          <div className="line-item-total with-border">
            <Text strong className="heading text-gray-700">
              Subtotal:{' '}
            </Text>
            <Text className="total">{formatCurrency(subTotal / 100)}</Text>
          </div>
        )}
        {!!totalVat && (
          <div className="line-item-total">
            <Text strong className="heading text-gray-700">
              VAT:{' '}
            </Text>
            <Text className="total">{formatCurrency(totalVat / 100)}</Text>
          </div>
        )}
        <div className="line-item-total with-border">
          <Text strong className="heading text-gray-700">
            Total:{' '}
          </Text>
          <Text className="total">
            {formatCurrency(totalIncludingVat ?? 0 / 100)}
          </Text>
        </div>
      </div>
    </ItemsCard>
  );
};

export default InvoiceViewItemsList;
