import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LogoMarkImage from '../Logo/logo-mark.svg';
import LogoImage from '../Logo/logo-new.svg';

const Wrapper = styled.span`
  cursor: pointer;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(${LogoImage});
  width: 100px;
  height: 54px;
  display: inline-block;
  margin-left: -2px;
  @media (max-width: 768px) {
    text-align: center;
    background-image: url(${LogoMarkImage});
    background-size: auto 32px;
    height: 61px;
    width: 35px;
    margin-left: 0;
  }
  img {
    height: 27px;
  }
`;

const Logo = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  };
  return <Wrapper onClick={handleClick} />;
};

export default Logo;
