import { ComplianceTaskType, ComplianceStatusType } from '../types';

/**
 * Calculates the overall compliance status based on a list of compliance tasks.
 *
 * @param tasks - An array of compliance tasks.
 * @returns Compliance status: 'compliant', 'nonCompliant', or 'dueSoon'.
 */
export const calculateTasksComplianceStatus = (
  tasks: ComplianceTaskType[]
): ComplianceStatusType => {
  // If no tasks are provided or the tasks array is empty, assume compliance.
  if (!Array.isArray(tasks) || tasks.length === 0) {
    return 'compliant';
  }

  // Filter tasks to include only those that are "incomplete" (not expired, lodged elsewhere, or complete).
  const activeTasks = tasks.filter(
    (task) =>
      !['expired', 'lodged_elsewhere', 'complete', 'refund'].includes(
        task.state
      )
  );

  // If there are no active tasks, the status is compliant.
  if (activeTasks.length === 0) {
    return 'compliant';
  }

  // If any active task is overdue, mark the status as non-compliant.
  if (activeTasks.some((task) => task.dueStatus === 'overdue')) {
    return 'nonCompliant';
  }

  // If any active task is due soon, mark the status as due soon.
  if (activeTasks.some((task) => task.dueStatus === 'dueSoon')) {
    return 'dueSoon';
  }

  // If none of the above conditions are met, the status is compliant.
  return 'compliant';
};
