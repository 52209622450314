import { Alert } from 'antd';
import styled from 'styled-components';

import Notice from './NoticeType';
import { createMarkup } from 'common/utils/strings';

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const NoticeListItem = ({
  message,
  type = 'warning',
  showIcon = false,
}: Notice) => {
  return (
    <Wrapper>
      <Alert
        type={type}
        // eslint-disable-next-line react/no-danger
        message={<div dangerouslySetInnerHTML={createMarkup(message)} />}
        showIcon={showIcon}
      />
    </Wrapper>
  );
};

export default NoticeListItem;
