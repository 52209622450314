import { LockOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';

const { Title, Paragraph } = Typography;

const plans = ['none', 'starter', 'pro', 'pro_vat', 'family'];

const Wrapper = styled.div`
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  .ant-typography {
    margin-bottom: 1rem;
  }

  .pricing-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    align-items: stretch;
    margin-top: 2rem;
  }

  .plan-label {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background: #e9f1f9;
    font-weight: 600;
    margin-bottom: 1rem;
    max-width: fit-content;
    font-size: 1.25rem;
  }

  .price {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    .period {
      font-size: 1rem;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .features {
    flex-grow: 1;
    margin: 2rem 0;
    text-align: left;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 0.75rem;
      padding-left: 1.5rem;
      position: relative;

      &:before {
        content: '+';
        position: absolute;
        left: 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;

    .pricing-container {
      gap: 1rem;
    }
  }
`;

const PricingCard: StyledComponent<'div', any, { $featured?: boolean }> =
  styled.div<{ $featured?: boolean }>`
    background: ${(props) => (props.$featured ? '#111827' : '#fff')};
    color: ${(props) => (props.$featured ? '#fff' : 'inherit')};
    border-radius: 1rem;
    padding: 2rem;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .plan-label {
      background: ${(props) =>
        props.$featured ? 'rgba(255, 255, 255, 0.1)' : '#f8f9fa'};
      color: ${(props) => (props.$featured ? '#fff' : 'inherit')};
    }

    .price .period {
      color: ${(props) =>
        props.$featured ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.45)'};
    }

    .ant-typography {
      color: ${(props) => (props.$featured ? '#fff' : 'inherit')};
    }

    @media (max-width: 768px) {
      transform: none;
    }
  `;

const StyledButton = styled(Button)`
  height: auto;
  padding: 1rem 2rem;
  font-size: 1rem;
  width: 100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

type UpsellProps = {
  requiredPlan?: 'none' | 'starter' | 'pro' | 'pro_vat' | 'family';
};

const Upsell = ({ requiredPlan }: UpsellProps) => {
  const history = useHistory();

  const planRank = plans.indexOf(requiredPlan ?? 'none');

  return (
    <Wrapper>
      <HeadingContainer>
        <Title level={1}>
          <LockOutlined /> You can&apos;t access this page.
        </Title>
        <Paragraph style={{ textAlign: 'left' }}>
          Subscribe to our comprehensive plans to grow with your business,
          offering continuous support to keep you compliant and stress-free.
        </Paragraph>
        <Paragraph style={{ textAlign: 'left' }} strong>
          You can upgrade to the following plans to get access to this feature:
        </Paragraph>
      </HeadingContainer>

      <div className="pricing-container">
        {/* {planRank <= 1 && (
          <PricingCard $featured>
            <span className="plan-label">Starter</span>
            <div className="price">
              R585 <span className="period">/month</span>
            </div>
            <Paragraph>
              Everything you need to manage your company finances, stay
              compliant, and handle tax filings—all in one place.
            </Paragraph>

            <div className="features">
              <Title level={5}>What&apos;s included</Title>
              <ul>
                <li>CIPC annual returns</li>
                <li>Beneficial ownership register</li>
                <li>SARS registered representative</li>
                <li>SARS tax returns</li>
                <li>Bookkeeping and accounting powered by Govchain Books™</li>
              </ul>
            </div>

            <StyledButton
              onClick={() => history.push('/subscriptions')}
              type="primary"
            >
              Choose starter plan
            </StyledButton>
          </PricingCard>
        )} */}

        {planRank <= 2 && (
          <PricingCard $featured>
            <span className="plan-label">Pro</span>
            <div className="price">
              R995 <span className="period">/month</span>
            </div>
            <Paragraph>
              Perfect for growing businesses that want all compliance filings
              automatically included and advanced accounting tools to save time,
              cut costs, and grow with confidence.
            </Paragraph>

            <div className="features">
              <Title level={5}>What&apos;s included</Title>
              {/* {planRank <= 1 && <Text>Everything in the Free plan, plus:</Text>} */}
              <ul>
                {planRank >= 1 && (
                  <>
                    <li>
                      Compliance tracking with reminders and alerts to keep your
                      business compliant.
                    </li>
                    <li>
                      Accounting tools to track income, expenses, and invoices,
                      so you stay organized and get paid fast.
                    </li>
                    <li>
                      Email and live chat support for questions and
                      troubleshooting during business hours
                    </li>
                  </>
                )}
                <li>
                  All compliance filings included—no extra costs, no stress,
                  complete peace of mind
                </li>
                <li>
                  Smart accounting tools to save time and make better
                  decisions—auto-tag transactions, process receipts instantly,
                  and get clear financial reports
                </li>
                <li>
                  Priority support with faster response times and access to
                  experts when you need them.
                </li>
              </ul>
            </div>

            <StyledButton
              type="primary"
              onClick={() => history.push('/subscriptions')}
            >
              Choose pro plan
            </StyledButton>
          </PricingCard>
        )}
      </div>
    </Wrapper>
  );
};

export default Upsell;
