import { legacyParse } from '@date-fns/upgrade/v2';
import currency from 'currency.js';
import { format } from 'date-fns';

import { VatReceipt } from '../../types';
import { Transaction } from 'modules/accounting/Transactions/types';

export const createCompletedTransactions = (
  suggestedTransactions: Partial<Transaction>[],
  transactions: Transaction[]
) => {
  const completedTransactions = suggestedTransactions
    .map((transaction) => {
      const matchedTransaction = transactions.find(
        (trans) => trans.pk === transaction.pk
      );

      return {
        ...matchedTransaction,
      };
    })
    .filter((trans) => trans);

  return completedTransactions;
};

export const isValidDate = (date: string) => {
  // Regular expressions for the date formats
  const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
  const regexYYYYMM = /^\d{4}-\d{2}$/;
  const regexYYYY = /^\d{4}$/;

  return (
    regexYYYYMMDD.test(date) || regexYYYYMM.test(date) || regexYYYY.test(date)
  );
};

export const formatCurrency = (amount?: number) => {
  const amountToConvert = amount || 0;
  return currency(amountToConvert, {
    symbol: 'R ',
    precision: 2,
    fromCents: true,
  }).format();
};

export const formatDate = (date: string) => {
  if (typeof date === 'string' && isValidDate(date)) {
    return format(legacyParse(date), 'dd MMM, yyyy');
  }
  return '';
};

export const formatFileName = (storageUrl: string) => {
  return storageUrl.split('/').pop();
};

export const getFileExtension = (fileUrl: string) => {
  if (!fileUrl) return '';
  return fileUrl.split('.').pop() as string;
};

export const getDocumentCounts = (documents: VatReceipt[]) => {
  const totalDocuments = documents.length;
  const completedDocuments = documents.filter(
    (doc) => doc.transactions?.length
  ).length;
  const percentComplete = (completedDocuments / totalDocuments) * 100;
  return {
    totalDocuments,
    completedDocuments,
    percentComplete,
  };
};
