import styled from 'styled-components';

import NoticeListItem from './NoticeListItem';

import Notice from './NoticeType';

const Wrapper = styled.div`
  margin-bottom: 2em;
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export type NoticeListProps = {
  notices?: Notice[];
};

const NoticeList = ({ notices = [] }: NoticeListProps) => (
  <Wrapper>
    {notices &&
      notices.length > 0 &&
      notices.map(({ message, type, showIcon }) => (
        <NoticeListItem
          key={message}
          message={message}
          type={type && type}
          showIcon={showIcon}
        />
      ))}
  </Wrapper>
);

export default NoticeList;
