import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';

import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import CompaniesModal from './CompaniesModal';
import { handleCompanyName } from './utils';

const Wrapper = styled.span`
  margin-left: 16px;
  .ant-btn {
    font-size: 13px;
  }
  .ant-tag {
    margin-left: 4px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 18px;
  }
  .ant-btn > span + .anticon {
    margin-left: 4px;
  }
`;

type AccessLevelType = 'none' | 'starter' | 'pro' | 'pro_vat' | 'family';

type CompaniesMenuProps = {
  addNewCompany: () => void;
  companies: { pk: number; name: string }[];
  currentCompany: {
    pk: number;
    name: string;
    accessLevel: AccessLevelType;
    enterpriseNum: string;
  };
  setCurrentCompany: (pk: number) => void;
};

const accessLevelMap: Record<
  AccessLevelType | 'default',
  { color: string; text: string }
> = {
  family: { color: '#262626', text: 'Family' },
  pro_vat: { color: '#262626', text: 'Pro' },
  pro: { color: '#262626', text: 'Pro' },
  starter: { color: '#595959', text: 'Starter' },
  none: { color: 'default', text: 'Free' },
  default: { color: 'default', text: 'Free' },
};

export const CompaniesMenu = ({
  addNewCompany,
  companies,
  currentCompany,
  setCurrentCompany,
}: CompaniesMenuProps) => {
  const [open, setOpen] = React.useState(false);

  const { pk, name, accessLevel, enterpriseNum } = currentCompany;

  const accessLevelKey = accessLevel || 'default';
  const { color: tagColor, text: tagText } = accessLevelMap[accessLevelKey];

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '8px' }}>/</span>
        <Button onClick={() => setOpen(true)} type="text" size="small">
          {currentCompany && name
            ? handleCompanyName(name, isMobile).toUpperCase()
            : ''}
          {enterpriseNum && <Tag color={tagColor}>{tagText}</Tag>}
          <CaretDownOutlined />
        </Button>
      </div>
      <CompaniesModal
        companies={companies}
        currentCompanyPk={pk}
        open={open}
        onCancel={() => setOpen(false)}
        onSetCurrentCompany={setCurrentCompany}
        onAddNewCompany={addNewCompany}
      />
    </Wrapper>
  );
};

export default CompaniesMenu;
