export const createFullName = (firstName: string, lastName: string) =>
  `${firstName.toUpperCase()} ${lastName.toUpperCase()}`;

export const createInitials = (firstName: string, lastName: string) =>
  `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

export const removeDoubleSpaces = (string: string) =>
  string.replace(/ +(?= )/g, '');

export const removeAllWhiteSpace = (string: string) => {
  let result = string;
  result = result && result.trim();
  result = result && result.replace(/ /g, '');
  return result;
};

export const createMarkup = (string: string) => {
  return { __html: string };
};

export const removeSpecialCharacters = (string: string) =>
  string.replace(/[^a-zA-Z0-9 ]/g, '');

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const removeCommasAndPeriods = (str: string) => str.replace(/[,.]/g, '');

export const truncateString = (str: string, maxLength: number): string =>
  str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
