import { Alert, Button, Card, Tag, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const { Text } = Typography;

const PaymentCard = styled(Card)`
  .ant-card-body {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .payment-heading {
      margin-bottom: 12px;
    }
  }
`;

type PaymentDetailsProps = {
  loading: boolean;
  bankName: string;
  bankAccountHolderName: string;
  bankAccountNumber: string;
  bankAccountBranchCode: string;
  isVatInvoice?: boolean;
};

const PaymentDetails = ({
  loading = true,
  bankName,
  bankAccountHolderName,
  bankAccountNumber,
  bankAccountBranchCode,
  isVatInvoice = false,
}: PaymentDetailsProps) => {
  const history = useHistory();
  // ? Need to check if the bank details are available
  if (
    (!bankName ||
      !bankAccountHolderName ||
      !bankAccountNumber ||
      !bankAccountBranchCode) &&
    isVatInvoice
  ) {
    return (
      <PaymentCard loading={loading}>
        <div className="text-lg font-semibold text-gray-500">
          Payment Details:
        </div>
        <Text strong className="payment-heading">
          Payment Type: <Tag color="processing">EFT</Tag>
        </Text>
        <Text>Banking Details: Not available</Text>
        <Alert
          message="Banking details are required for a VAT Invoice."
          type="warning"
        />
        <Button
          type="primary"
          onClick={() => history.push('/accounting/bank-accounts')}
        >
          View Bank Accounts
        </Button>
      </PaymentCard>
    );
  }

  return (
    <PaymentCard>
      <div className="text-lg font-semibold text-gray-500">
        Payment Details:
      </div>
      <div className="flex gap-2 pb-4">
        <span className="font-semibold text-gray-700">Payment Type: </span>
        <Tag color="processing">EFT</Tag>
      </div>
      <div className="flex flex-col gap-2">
        <span className="font-semibold text-gray-600">
          EFT Banking Details:{' '}
        </span>
        <div className="ml-2">
          <span className="font-medium text-gray-800">Bank: </span>
          <span>
            {bankName
              ? bankName.charAt(0).toUpperCase() + bankName.slice(1)
              : bankName}
          </span>
        </div>
        <div className="ml-2">
          <span className="font-medium text-gray-800">Account Holder: </span>
          <span>{bankAccountHolderName}</span>
        </div>
        <div className="ml-2">
          <span className="font-medium text-gray-800">Account Number: </span>
          {bankAccountNumber}
        </div>
        <div className="ml-2">
          <span className="font-medium text-gray-800">Branch Code: </span>{' '}
          {bankAccountBranchCode}
        </div>
      </div>
    </PaymentCard>
  );
};

export default PaymentDetails;
