import { Tag, Typography } from 'antd';

import { isAfter, formatDistanceStrict } from 'date-fns';
import Fuse from 'fuse.js';

import humps from 'humps';
import adminApi from 'common/api';
import { Contact } from 'modules/accounting/Contacts/types';
import { Invoice, Item } from 'modules/accounting/Invoices/types';

const { Text } = Typography;

const fuseOptions = {
  keys: ['invoiceName', 'invoiceEmail'],
  threshold: 0,
  ignoreLocation: true,
};

export const handleFilters = (
  invoices: Invoice[],
  searchString?: string,
  state?: string
) => {
  let filteringInvoices = [...invoices];

  if (state?.toLocaleLowerCase() !== 'all') {
    filteringInvoices = filteringInvoices.filter(
      (invoice) => invoice.state === state?.toLocaleLowerCase()
    );
  }

  if (searchString) {
    const fuse = new Fuse(filteringInvoices, fuseOptions);
    const result = fuse.search(searchString);
    filteringInvoices = result.map((searchedInvoice) => searchedInvoice.item);
  }

  filteringInvoices.sort((a, b) => {
    if (!a.created || !b.created) return 0;

    return new Date(b.created).getTime() - new Date(a.created).getTime();
  });

  return filteringInvoices;
};

export const handleCreateInvoices = async (data: Partial<Invoice>) => {
  const response = await adminApi.accounting.invoices.createInvoice(data);
  return response;
};

export const handleGetInvoices = async (id?: string) => {
  let invoiceResponse = [];

  if (id) {
    const requestResponse =
      await adminApi.accounting.invoices.getInvoiceById(id);
    invoiceResponse = [{ ...requestResponse }];
  } else {
    invoiceResponse = await adminApi.accounting.invoices.getInvoices();
  }

  return invoiceResponse;
};

export const handleUpdateInvoice = async (
  id: string | number,
  data: Partial<Invoice>
) => {
  // ? We need to remove the PDF Url from the data object if it's not present
  // ? Otherwise we might override it when it becomes available.
  const cleanedData = { ...data };
  if (!cleanedData.pdfUrl) {
    delete cleanedData.pdfUrl;
  }

  return adminApi.accounting.invoices.updateInvoice(id, cleanedData);
};

export const handleApprove = async (id: string | number) => {
  return adminApi.accounting.invoices.approveInvoice(id);
};

export const handleSendInvoiceEmail = async (id: string | number) => {
  return adminApi.accounting.invoices.sendInvoiceEmail(id);
};

export const handleDeleteInvoice = async (id: string | number) => {
  return adminApi.accounting.invoices.deleteInvoice(id);
};

export const handleCopyInvoice = async (id: string | number) => {
  return adminApi.accounting.invoices.copyInvoice(id);
};

export const capitalise = (word: string) => {
  if (!word || typeof word !== 'string') return word;

  return word.charAt(0).toUpperCase() + word.slice(1);
};

const convertDecimalsToInteger = (number: number) => Math.round(number * 100);

export const convertTextAreaToLineBreaks = (text: string) =>
  text.replace(/\r\n|\r|\n/g, '</br>');

export const formatInvoiceForApi = (
  formValues: any,
  companyId: number,
  addedContact: Contact,
  items: Item[],
  isRegisteredForVat: boolean
) => {
  let fixedFormFieldValues = { ...formValues };

  if (fixedFormFieldValues.dateIssued) {
    fixedFormFieldValues.dateIssued =
      fixedFormFieldValues.dateIssued.format('YYYY-MM-DD');
    if (fixedFormFieldValues.dateDue) {
      fixedFormFieldValues.dateDue =
        fixedFormFieldValues.dateDue.format('YYYY-MM-DD');
    } else {
      delete fixedFormFieldValues.dateIssued;
    }
  }

  if (addedContact?.pk) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      customer: fixedFormFieldValues.customer || addedContact.pk || '',
    };
  }

  if (
    fixedFormFieldValues.bankAccountId?.id ||
    typeof fixedFormFieldValues.bankAccountId === 'object'
  ) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      bankAccountId: fixedFormFieldValues.bankAccountId.id,
    };
  }

  if (
    fixedFormFieldValues.customer?.pk ||
    typeof fixedFormFieldValues.customer === 'object'
  ) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      customer: fixedFormFieldValues.customer.pk,
    };
  }

  if (items.length > 0) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      lineItems: items,
    };
  }

  if (isRegisteredForVat) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      vatIncluded: true,
    };
  }

  if (companyId) {
    fixedFormFieldValues = {
      ...fixedFormFieldValues,
      company: companyId,
    };
  }

  if (fixedFormFieldValues.notes?.length) {
    fixedFormFieldValues.notes = convertTextAreaToLineBreaks(
      fixedFormFieldValues.notes
    );
  }

  if (fixedFormFieldValues.lineItems) {
    fixedFormFieldValues.lineItems = fixedFormFieldValues.lineItems.map(
      (item: Item) => {
        return {
          ...item,
          price: item.price,
        };
      }
    );
  }

  if (!fixedFormFieldValues.lineItems) {
    fixedFormFieldValues.lineItems = [];
  }

  return fixedFormFieldValues;
};

export const convertItemsToInteger = (invoiceItems: Item[]) => {
  if (!invoiceItems) return [];
  const newList: any = [];
  if (invoiceItems.length > 0) {
    invoiceItems.map((item) =>
      newList.push({ ...item, rate: convertDecimalsToInteger(item.price) })
    );
  }
  return newList;
};

export const createInvoicePayload = (values: any) => {
  let newValues = {
    ...values,
    billTo: values.customer,
    invoiceitems: convertItemsToInteger(values.lineItems),
    amountPaid: convertDecimalsToInteger(values.amountPaid) || 0,
    createdDate: values.dateIssued,
    dueDate: values.dateDue,
    invoiceNum: values.invoiceNumber,
    paymentTerms: '',
    discounts: 0,
    vat: values.vatAmount,
    shipping: 0,
    state: 'new',
    notes: '',
    terms: '',
  };

  newValues = humps.decamelizeKeys(newValues);
  return newValues;
};

export const isValidCustomerForVatInvoice = (customer: Contact) => {
  if (!customer) return false;

  if (customer.vatNumber && customer.vatNumber !== '') {
    return false;
  }
  // add other checks here as we figure it out

  return true;
};

// ? Component generators

export const getActionForState = (state: string) => {
  if (state.toLowerCase() === 'issued') {
    return 'Mark as paid';
  }

  if (state.toLowerCase() === 'paid') {
    return 'Mark as unpaid';
  }

  if (state.toLowerCase() === 'draft') {
    return 'Approve invoice';
  }

  return 'Send to contact';
};

export const getDueDate = (dueDate: string) => {
  const isOverdue = isAfter(new Date(), new Date(dueDate));
  const daysToDueDate = formatDistanceStrict(new Date(), new Date(dueDate), {
    unit: 'day',
  });

  if (daysToDueDate === '0 days') {
    return <Text> Due today </Text>;
  }

  if (isOverdue) {
    return <Text>Overdue by {daysToDueDate}</Text>;
  }
  // I need to split out from the string text that gets returned from date-fns
  if (+daysToDueDate.split(' ')[0] > 10) {
    return <Text>Due in {daysToDueDate}</Text>;
  }

  return <Text>Due in {daysToDueDate}</Text>;
};

export const getStateTag = (state?: string) => {
  if (!state) return null;

  if (state.toLowerCase() === 'draft' || state.toLowerCase() === 'new') {
    return <Tag className="state-tag">Draft</Tag>;
  }

  if (state.toLowerCase() === 'issued') {
    return (
      <Tag className="state-tag" color="processing">
        Approved
      </Tag>
    );
  }

  if (state.toLowerCase() === 'sent') {
    return (
      <Tag className="state-tag" color="warning">
        {capitalise(state)}
      </Tag>
    );
  }

  if (state.toLowerCase() === 'void') {
    return (
      <Tag className="state-tag" color="error">
        {capitalise(state)}
      </Tag>
    );
  }

  if (state.toLowerCase() === 'paid') {
    return (
      <Tag className="state-tag" color="success">
        {capitalise(state)}
      </Tag>
    );
  }

  return 'default';
};

export const getFrequencyTag = (
  frequency?: string,
  active: boolean = false
) => {
  if (active && frequency) {
    return <Tag color="blue">Repeating</Tag>;
  }
  if (!active && frequency) {
    return <Tag color="default">Not Repeating</Tag>;
  }

  return null;
};

export const handleWhatsAppShare = (message: string) => {
  const encodedMessage = encodeURIComponent(message);
  window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
};

export const handleCopy = (url: string) => {
  navigator.clipboard.writeText(url);
};
