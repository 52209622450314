import { useState, useEffect } from 'react';
import { Invoice } from '../types';
import { endpoints } from 'common/api/endpoints';

interface UseSharedInvoiceProps {
  invoiceId: string;
}

export const useSharedInvoice = ({ invoiceId }: UseSharedInvoiceProps) => {
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (!invoiceId) return;

      setIsLoading(true);
      setError(null);

      try {
        const response =
          await endpoints.accounting.invoices.getPublicInvoice(invoiceId);
        setInvoice(response);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId]);

  return {
    invoice,
    isLoading,
    error,
  };
};
