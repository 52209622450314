import { PlusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Modal, List, Button, Tag, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const ListWrapper = styled.div`
  margin-top: -16px;

  .list-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .company-name:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export type CompaniesModalProps = {
  companies: { pk: number; name: string }[];
  currentCompanyPk: number;
  open: boolean;
  onCancel: () => void;
  onSetCurrentCompany: (pk: number) => void;
  onAddNewCompany: () => void;
};

const CompaniesModal = ({
  companies,
  currentCompanyPk,
  open,
  onCancel,
  onSetCurrentCompany,
  onAddNewCompany,
}: CompaniesModalProps) => {
  const sortedCompanies = [...companies].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Modal title="Companies" open={open} onCancel={onCancel} footer={null}>
      <ListWrapper>
        <List
          //   size="small"
          header={<div>Switch company to...</div>}
          footer={
            <Button
              block
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => onAddNewCompany()}
            >
              Add New Company
            </Button>
          }
        >
          {sortedCompanies.map((company) => {
            const isCurrentCompany = company.pk === currentCompanyPk;
            return (
              <List.Item
                key={company.pk}
                className={isCurrentCompany ? 'active' : ''}
              >
                <div className="list-item-wrapper">
                  <Text
                    className="company-name"
                    onClick={() => onSetCurrentCompany(company.pk)}
                  >
                    {company.name.toUpperCase()}
                  </Text>
                  {isCurrentCompany ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Active
                    </Tag>
                  ) : (
                    <Button
                      type="link"
                      onClick={() => onSetCurrentCompany(company.pk)}
                      size="small"
                    >
                      Switch
                    </Button>
                  )}
                </div>
              </List.Item>
            );
          })}
        </List>
      </ListWrapper>
    </Modal>
  );
};

export default CompaniesModal;
