import type { ReactNode } from 'react';
import {
  AppstoreFilled,
  FolderFilled,
  ShopFilled,
  ProfileFilled,
  ToolFilled,
  CalculatorFilled,
  UserOutlined,
  ContainerFilled,
  LogoutOutlined,
  CarryOutFilled,
  GoldFilled,
} from '@ant-design/icons';

type MenuItem = {
  key: string;
  icon?: ReactNode;
  label?: string;
  path?: string;
  children?: Omit<MenuItem, 'icon' | 'children'>[];
  type?: 'divider';
};

export const menuItems: MenuItem[] = [
  {
    key: 'dashboard',
    icon: <AppstoreFilled />,
    label: 'Dashboard',
    path: '/dashboard',
  },
  {
    key: 'compliance',
    icon: <CarryOutFilled />,
    label: 'Compliance',
    path: '/compliance',
  },
  {
    key: 'documents',
    icon: <FolderFilled />,
    label: 'Documents',
    path: '/documents',
  },
  {
    key: 'store',
    icon: <ShopFilled />,
    label: 'Store',
    path: '/store',
  },
  {
    key: 'profile',
    icon: <ProfileFilled />,
    label: 'Profile',
    path: '/profile',
  },
  {
    key: 'tools',
    icon: <ToolFilled />,
    label: 'Tools',
    path: '/tools',
  },
  {
    key: 'accounting',
    icon: <CalculatorFilled />,
    label: 'Books',
    children: [
      { label: 'Contacts', key: 'contacts', path: '/accounting/contacts' },
      { label: 'Invoices', key: 'invoices', path: '/accounting/invoices' },
      { label: 'Receipts', key: 'receipts', path: '/accounting/receipts' },
      {
        label: 'Transactions',
        key: 'transactions',
        path: '/accounting/transactions',
      },
      {
        label: 'Bank Accounts',
        key: 'bankaccounts',
        path: '/accounting/bank-accounts',
      },
    ],
  },
  { type: 'divider', key: 'divider' },
  {
    key: 'user-profile',
    label: 'Profile',
    icon: <UserOutlined />,
    path: '/account/profile',
  },
  {
    key: 'billing',
    label: 'Billing',
    icon: <ContainerFilled />,
    path: '/account/billing',
  },
  {
    key: 'plans',
    label: 'Plans',
    icon: <GoldFilled />,
    path: '/subscriptions',
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
    path: '/logout',
  },
];
