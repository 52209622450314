import {
  UserOutlined,
  ContainerOutlined,
  LogoutOutlined,
  GoldOutlined,
} from '@ant-design/icons';
import { Modal, Menu } from 'antd';
import styled from 'styled-components';

const MenuWrapper = styled.div`
  margin-top: -16px;
  .ant-menu-inline {
    border-right: none;
    border-inline-end: none !important;
  }
`;

export type AccountModalProps = {
  open: boolean;
  onCancel: () => void;
  onMenuClick: (e: any) => void;
};

const items = [
  { label: 'Profile', key: 'profile', icon: <UserOutlined /> },
  { label: 'Billing', key: 'billing', icon: <ContainerOutlined /> },
  { label: 'Plans', key: 'plans', icon: <GoldOutlined /> },
  // { label: 'Orders', key: 'orders', icon: <ShoppingCartOutlined /> },
  { type: 'divider' },
  { label: 'Logout', key: 'logout', icon: <LogoutOutlined /> },
];

const AccountModal = ({ open, onCancel, onMenuClick }: AccountModalProps) => {
  return (
    <Modal
      title="Account"
      open={open}
      onCancel={onCancel}
      footer={null}
      width={304}
    >
      <MenuWrapper>
        <div className="my-8">
          <Menu
            onClick={onMenuClick}
            style={{ width: 256 }}
            mode="inline"
            // @ts-ignore
            items={items}
          />
        </div>
      </MenuWrapper>
    </Modal>
  );
};

export default AccountModal;
