import { CompanyComplianceBanner } from './CompanyComplianceBanner';
import useComplianceTasks from '../../hooks/useComplianceTasks';

const CompanyComplianceBannerProvider = () => {
  const { complianceStatus, loading } = useComplianceTasks();

  if (loading) return null;

  if (complianceStatus === 'compliant') return null;
  return (
    <div className="mb-4">
      <CompanyComplianceBanner status={complianceStatus} />
    </div>
  );
};

export default CompanyComplianceBannerProvider;
