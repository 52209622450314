export const handleCompanyName = (
  companyName: string,
  isMobile: boolean,
  maxLength: number = 16
) => {
  const trimmedName = companyName.trim();

  if (isMobile && trimmedName.length > maxLength) {
    return `${trimmedName.substring(0, maxLength)}...`;
  }

  return trimmedName;
};
