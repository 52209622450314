import React from 'react';
import { geekblue } from '@ant-design/colors';
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';

const validateMessages = {
  required: 'This field is required',
};

type AntDesignProviderProps = {
  children: React.ReactNode;
};

export const AntDesignProvider = ({ children }: AntDesignProviderProps) => {
  return (
    <StyleProvider layer>
      <ConfigProvider
        form={{ validateMessages }}
        theme={{ token: { colorPrimary: geekblue.primary } }}
      >
        {children}
      </ConfigProvider>
    </StyleProvider>
  );
};

export default AntDesignProvider;
