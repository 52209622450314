import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../Footer/Footer';
import LogoImage from '../Logo/logo-new.svg';

// @ts-ignore
const promoUrl: string = process.env.REACT_APP_PROMO_URL;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const Wrapper = styled.div`
  background: white;
  padding: 54px 30px 70px 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: -70px;
  @media (max-width: 992px) {
    margin-bottom: -113px;
    padding-bottom: 173px;
  }
`;

const Inner = styled.div`
  width: 560px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const Logo = styled.div`
  margin-bottom: 2em;
  img {
    height: 22px;
    cursor: pointer;
  }
`;

const Subtitle = styled.p`
  margin-bottom: 3em;
  color: #86939a;
`;

const StyledLink = styled(Link)`
  line-height: 2em;
  display: block;
`;

export const logoLink = () => {
  window.location.assign(promoUrl);
};

type AuthenticationPageProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  links?: { label: string; link: string }[];
  handleLogoLink?: () => void;
};

const AuthenticationPage = ({
  children,
  title,
  subtitle = '',
  links = [],
  handleLogoLink = logoLink,
}: AuthenticationPageProps) => (
  <div>
    <Wrapper>
      <Inner>
        <Logo>
          <img src={LogoImage} alt="logo" id="logo" onClick={handleLogoLink} />
        </Logo>
        <h1 className="mb-6 border-t border-gray-900 pt-4 text-2xl font-bold text-gray-800">
          {title}
        </h1>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
        {links.length > 0 &&
          links.map((link) => (
            <StyledLink key={link.label} to={link.link}>
              {link.label}
            </StyledLink>
          ))}
      </Inner>
    </Wrapper>
    <Footer />
  </div>
);

export default AuthenticationPage;
