import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CompaniesMenu from './CompaniesMenu';

import { analytics } from 'common/analytics/analytics';
import {
  ADD_NEW_COMPANY_NEW_CLICKED,
  CHANGE_COMPANY_CLICKED,
} from 'common/analytics/events';
import { setUserCurrentCompanyById } from 'common/reducers/companies';
import { CompanyStateType } from 'common/types/CompanyType';
import { handleCompanyRedirectUrl } from 'common/utils/authUtils';

const CompaniesMenuContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.user.token);
  const companies = useSelector((state: any) => state.companies.companies);
  const currentCompany = useSelector(
    (state: any) => state.companies.currentCompany
  );

  const handleAddNewCompany = () => {
    analytics.track(ADD_NEW_COMPANY_NEW_CLICKED);
    history.push('/companys/add');
  };

  const handleSetCurrentCompany = async (companyId: number) => {
    analytics.track(CHANGE_COMPANY_CLICKED);
    // @ts-ignore
    const newCompany: { state: CompanyStateType } = await dispatch(
      setUserCurrentCompanyById(companyId, token)
    );
    const { state } = newCompany;
    const redirectUrl = handleCompanyRedirectUrl(state);
    history.push(redirectUrl);
  };

  return (
    <CompaniesMenu
      addNewCompany={handleAddNewCompany}
      companies={companies}
      currentCompany={currentCompany}
      setCurrentCompany={handleSetCurrentCompany}
    />
  );
};

export default CompaniesMenuContainer;
