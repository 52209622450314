import { ComplianceTaskApiType, ComplianceStateType } from '../types';

/** Gets the current date for comparison */
const getCurrentDate = () => new Date();

/** States that indicate a task is no longer active/pending */
const COMPLETED_STATES = [
  'complete',
  'lodged_elsewhere',
  'expired',
] as const as readonly ComplianceStateType[];

/**
 * Determines if a compliance task is overdue based on its state and due date
 * @param task - The compliance task to check
 * @returns boolean - True if the task is overdue (not completed and past due date)
 */
export const isTaskOverdue = (task: ComplianceTaskApiType): boolean => {
  return (
    !COMPLETED_STATES.includes(task.state) &&
    new Date(task.dueDate) < getCurrentDate()
  );
};
