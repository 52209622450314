import { Typography, Card } from 'antd';
import styled from 'styled-components';
import { ContactAddress } from 'modules/accounting/Contacts/types';

const { Text } = Typography;

const CustomerCard = styled(Card)`
  .ant-card-body {
    padding: 12px;

    .customer-details {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
    }
    .address-container {
      display: flex;
      flex-direction: column;
    }
    .vat-number {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }

    .customer-name {
      margin-bottom: 0px;
    }
  }
`;

type CustomerDetailsProps = {
  customerName: string;
  customerEmail: string;
  customerAddress?: ContactAddress;
  customerVatNumber?: string | number;
};

const CustomerDetails = ({
  customerName,
  customerEmail,
  customerAddress,
  customerVatNumber,
}: CustomerDetailsProps) => {
  return (
    <CustomerCard>
      <div className="text-lg font-semibold text-gray-500">Billed To: </div>
      <div className="customer-details">
        <div className="customer-name font-bold">{customerName}</div>
        <div className="text-sm text-gray-500">{customerEmail}</div>
        {customerVatNumber && (
          <div className="vat-number">
            <Text strong>VAT Number:</Text>
            <Text>{customerVatNumber}</Text>
          </div>
        )}
      </div>

      <div className="address-container">
        <Text strong className="font-semibold text-gray-600">
          Business Address:
        </Text>
        <Text className="pr-2">{customerAddress?.addressLine1}</Text>
        {customerAddress?.addressLine2 && (
          <Text className="pr-2">{customerAddress?.addressLine2}</Text>
        )}
        <Text className="pr-2">{customerAddress?.city}</Text>
        <Text className="pr-2">{customerAddress?.province}</Text>
        <Text className="pr-2">{customerAddress?.postalCode}</Text>
      </div>
    </CustomerCard>
  );
};

export default CustomerDetails;
