import { RocketOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const UpgradePlanBanner = () => {
  const history = useHistory();

  return (
    <div
      className="cursor-pointer rounded-md bg-orange-100 px-3 py-2 transition-colors hover:bg-orange-200"
      onClick={() => history.push('/subscriptions')}
      onKeyDown={(e) => e.key === 'Enter' && history.push('/subscriptions')}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center gap-3 text-sm text-gray-700">
        <RocketOutlined />
        Upgrade your plan to access more features.
      </div>
    </div>
  );
};

export default UpgradePlanBanner;
