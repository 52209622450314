import { client } from './client';

import { InvoiceApiResponse } from 'common/components/InvoiceGenerator/types';
import { Invoice } from 'modules/account/Billing/types';

import { Transaction } from 'modules/accounting//Transactions/types';
import { Account } from 'modules/accounting/Accounts/types/account';
import {
  BankConnectLink,
  BankConnectBasicState,
  BankConnectAccounts,
  MfaAccountSync,
  MfaAccountInput,
} from 'modules/accounting/BankAccounts/components/BankAccountConnect/types';
import {
  BankAccountFormType,
  BankAccountType,
} from 'modules/accounting/BankAccounts/types';
import { Contact } from 'modules/accounting/Contacts/types';
import { Invoice as AccountingInvoice } from 'modules/accounting/Invoices/types';
import { AccountStatementType } from 'modules/accounting/Reports/AccountsStatement/types';
import { TrailBalanceType } from 'modules/accounting/Reports/TrailBalances/types';
import { IncomeStatementType } from 'modules/accounting/Reports/types';
import {
  AddressChange,
  AddressChangeFormValues,
} from 'modules/cipc/AddressChange/types';
import { AnnualReturn } from 'modules/cipc/AnnualReturns/types';
import { Card } from 'modules/company/Dashboard/types';
import { CoidRenewal } from 'modules/labour/LogsRenewal/types';
import { TaxClearanceType } from 'modules/sars/TaxClearance/types';
import { RegisteredRep } from 'modules/sars/types/RegisteredRep';
import { Order } from 'modules/shop/Pay/types';
import {
  ActiveSubscriptionItem,
  Subscription,
} from 'modules/subcriptions/types';

const accounting = {
  accounts: {
    getAccounts: (): Promise<Account[]> => client.get('/accounting/account/'),
  },
  bankAccounts: {
    getBankAccounts: (): Promise<BankAccountType[]> =>
      client.get('/accounting/mybankaccount/'),
    getSessionStatus: (sessionId: string): Promise<any> =>
      client.get(`/bankconnect/session-status/${sessionId}`),
    createSession: (): Promise<any> =>
      client.get('/bankconnect/connect/create-session/'),
    createBankAccount: (
      data: BankAccountFormType
    ): Promise<BankAccountFormType> =>
      client.post('/accounting/mybankaccount/', data),
    updateBankAccount: (
      pk: number,
      data: Partial<BankAccountType>
    ): Promise<BankAccountType> =>
      client.patch(`/accounting/mybankaccount/${pk}/`, data),
  },
  bankConnect: {
    getBankConnectList: (): Promise<any> =>
      client.get('/bankconnect/connect/list'),
    getConnectStatus: (): Promise<any> =>
      client.get('/bankconnect/connect/status/'),
    setBankConnectLink: (data: any): Promise<BankConnectLink> =>
      client.post('/bankconnect/connect/link/', data),
    setMfaInput: (data: any): Promise<any> =>
      client.post('/bankconnect/connect/submit-input/', data),
    setBankConnectId: (
      accountId: string | number
    ): Promise<BankConnectBasicState> =>
      client.post('/bankconnect/connect/accounts-sync/', { accountId }),
    getBankAccounts: (): Promise<BankConnectAccounts> =>
      client.get('/bankconnect/connect/accounts/'),
    pollBankConnectLink: (): Promise<BankConnectLink> =>
      client.get('/bankconnect/connect/poll-link/'),
    syncMfaAccount: (bankAccountId: string | number): Promise<MfaAccountSync> =>
      client.post('/bankconnect/connect/sync-mfa-account/', { bankAccountId }),
    syncMfaInput: (data: any): Promise<MfaAccountInput> =>
      client.post('/bankconnect/connect/sync-mfa-input/', data),
  },
  bankStatements: {
    getStatements: (): Promise<any> => client.get('/accounting/bankstatement/'),
    getStatementById: (id: number): Promise<any> =>
      client.get(`/accounting/bankstatement/${id}`),
    createBankStatement: (data: any): Promise<any> =>
      client.post('/accounting/bankstatement/', data),
    updateBankStatement: (pk: number, data: any): Promise<any> =>
      client.patch(`/accounting/bankstatement/${pk}/`, data),
  },
  contacts: {
    getContacts: (): Promise<any> => client.get('/contacts/contact'),
    getContactById: (id: string | number): Promise<Contact> =>
      client.get(`/contacts/contact/${id}`),
    getContactsByType: (type: 'supplier' | 'customer'): Promise<Contact[]> =>
      client.get(`/contacts/contact/?type=${type}`),
    createContact: (data: any): Promise<Contact> =>
      client.post('/contacts/contact/', data),
    updateContact: (
      id: string | number,
      data: Partial<Contact>
    ): Promise<any> => client.patch(`/contacts/contact/${id}/`, data),
    deleteContact: (id: string | number): Promise<any> =>
      client.delete(`/contacts/contact/${id}/`),
  },
  invoices: {
    getInvoices: (): Promise<AccountingInvoice[]> =>
      client.get('/invoices/invoice/'),
    getInvoiceById: (id: string | number): Promise<AccountingInvoice> =>
      client.get(`/invoices/invoice/${id}/`),
    createInvoice: (
      data: Partial<AccountingInvoice>
    ): Promise<AccountingInvoice> => client.post('/invoices/invoice/', data),
    copyInvoice: (id: string | number): Promise<AccountingInvoice> =>
      client.post(`/invoices/invoice/${id}/copy/`),
    updateInvoice: (
      id: string | number,
      data: Partial<AccountingInvoice>
    ): Promise<any> => client.patch(`/invoices/invoice/${id}/`, data),
    approveInvoice: (id: string | number): Promise<any> =>
      client.patch(`/invoices/invoice/${id}/issue/`),
    sendInvoiceEmail: (id: string | number): Promise<any> =>
      client.post(`/invoices/invoice/${id}/email/`),
    deleteInvoice: (id: string | number): Promise<any> =>
      client.delete(`/invoices/invoice/${id}/`),
    getPublicInvoice: (invoiceId: string): Promise<any> =>
      client.get(`/invoices/invoice-public/${invoiceId}`),
  },
  recurringInvoices: {
    getRecurringInvoices: (): Promise<any> =>
      client.get('/invoices/scheduler/'),
    getRecurringInvoiceById: (pk: string | number): Promise<any> =>
      client.get(`/invoices/scheduler/${pk}/`),
    activateRecurringInvoice: (pk: string | number): Promise<any> =>
      client.post(`/invoices/scheduler/${pk}/activate/`),
    disableRecurringInvoice: (pk: string | number): Promise<any> =>
      client.post(`/invoices/scheduler/${pk}/disable/`),
    updateRecurringInvoice: (
      pk: string | number,
      data: Partial<{ frequency: 'weekly' | 'monthly' }>
    ): Promise<any> => client.patch(`/invoices/invoice/${pk}/`, data),
    createRecurringInvoice: (
      pk: string | number,
      frequency: 'weekly' | 'monthly'
    ): Promise<any> =>
      client.post(`/invoices/invoice/${pk}/schedule/`, { frequency }),
  },
  items: {
    // These are the invoice items
    getItems: (): Promise<any> => client.get('/invoices/item/'),
    getItemById: (id: string | number): Promise<any> =>
      client.get(`/invoices/item/${id}`),
    createItem: (data: any): Promise<any> =>
      client.post('/invoices/item/', data),
    updateItem: (id: string | number, data: any): Promise<any> =>
      client.patch(`/invoices/item/${id}/`, data),
    deleteItem: (id: string | number): Promise<any> =>
      client.delete(`/invoices/item/${id}/`),
  },
  transactions: {
    getTransactions: (): Promise<Transaction[]> =>
      client.get('/accounting/transactions/'),
    getTransactionsByDate: (
      startDate: string,
      endDate: string
    ): Promise<Transaction[]> =>
      client.get(
        `/accounting/transactions/?start_date=${startDate}&end_date=${endDate}`
      ),
    getAccountTransactions: (bankAccountId: string): Promise<Transaction[]> =>
      client.get(`/accounting/transactions/?bankAccountId=${bankAccountId}`),

    updateTransactionAccount: (
      pk: number,
      account: number | null | undefined
    ): Promise<Transaction> =>
      client.patch(`accounting/transactions/${pk}/`, { account }),
    updateTransactionsAccount: (
      newTransactions: Transaction[]
    ): Promise<Transaction> =>
      client.patch('accounting/transactions/', newTransactions),
  },
  reports: {
    getIncomeStatementByDate: (
      startDate: string,
      endDate: string
    ): Promise<IncomeStatementType> =>
      client.get(
        `/accounting/transactions/income-statement/?start_date=${startDate}&end_date=${endDate}`
      ),
    getIncomeStatementDownload: (
      startDate: string,
      endDate: string
    ): Promise<{ url: string }> =>
      client.get(
        `/accounting/transactions/income-statement-download/?start_date=${startDate}&end_date=${endDate}`
      ),
    getTaxPeriodIncomeStatement: (periodPk: string | number): Promise<any> =>
      client.get(`/sars/taxreturnperiod/${periodPk}/sars-income-statement/`),
    getTaxPeriodIncomeStatementDownload: (periodPk: string | number) =>
      client.get(
        `/sars/taxreturnperiod/${periodPk}/sars-income-statement-download/`
      ),
    // Payables/receivables
    getPayablesReceivables: (startDate: string, endDate: string) =>
      client.get(
        `/accounting/journal/receivables-payables/?start_date=${startDate}&end_date=${endDate}`
      ),

    // Balance Sheet
    getBalanceSheet: (): Promise<any> =>
      client.get(`/accounting/journal/running-balance/`),
    getBalanceSheetByDate: (endDate: string): Promise<any> =>
      client.get(`/accounting/journal/running-balance/?end_date=${endDate}`),
    downloadBalanceSheet: (endDate: string): Promise<any> =>
      client.get(
        `/accounting/journal/running-balance-download/?end_date=${endDate}`
      ),
    // Account Statement
    getAccountStatementByDate: (
      startDate: string,
      endDate: string
    ): Promise<AccountStatementType> =>
      client.get(
        `/accounting/journal/journal-summary/?start_date=${startDate}&end_date=${endDate}`
      ),
    getAccountStatementDownload: (
      startDate: string,
      endDate: string
    ): Promise<any> =>
      client.get(
        `/accounting/journal/journal-summary-download/?start_date=${startDate}&end_date=${endDate}`
      ),
    // Trail Balances
    getTrailBalanceByDate: (
      startDate: string,
      endDate: string
    ): Promise<TrailBalanceType> =>
      client.get(
        `/accounting/journal/journal-summary/?start_date=${startDate}&end_date=${endDate}`
      ),
    getTrailBalanceDownload: (
      startDate: string,
      endDate: string
    ): Promise<any> =>
      client.get(
        `/accounting/journal/journal-summary-download/?start_date=${startDate}&end_date=${endDate}`
      ),
  },
  receipts: {
    getDocuments: (reconcile?: boolean): Promise<any> => {
      if (reconcile) {
        return client.get('/accounting/receipt/?user-review=true');
      }
      return client.get('/accounting/receipt/');
    },
    getDocumentsById: (id: number): Promise<any> =>
      client.get(`/accounting/receipt/${id}`),
    getDocumentsByContactId: (contactId: number): Promise<any> =>
      client.get(`/accounting/receipt/?contactid=${contactId}`),
    getDocumentsWithSuggestedTransactions: (): Promise<any> =>
      client.get('/accounting/receipt/?include-transactions=true'),
    getDocumentsByIdWithSuggestedTransactions: (id: number): Promise<any> =>
      client.get(`/accounting/receipt/${id}/?include-transactions=true`),
    createDocument: (data: any): Promise<any> =>
      client.post('/accounting/receipt/', data),
    updateDocument: (id: any, data: any): Promise<any> =>
      client.patch(`/accounting/receipt/${id}/`, data),
    deleteDocumentById: (id: number): Promise<any> =>
      client.delete(`/accounting/receipt/${id}`),
    getReceiptTransactionsBySearch: (
      receiptPk: number,
      search: string
    ): Promise<Transaction[]> =>
      client.get(
        `/accounting/receipt/${receiptPk}/search-transactions/?search=${search}`
      ),
    reconcileReceipt: (
      receiptPk: number,
      data: { payMethod: string }
    ): Promise<any> =>
      client.patch(`/accounting/receipt/${receiptPk}/reconcile-receipt/`, data),
    undoReconcileReceipt: (receiptPk: number): Promise<any> =>
      client.post(`/accounting/receipt/${receiptPk}/undo-reconcile/`),
  },
};

const accounts = {
  users: {
    createUser: (data: any): Promise<any> =>
      client.post('/accounts/users/', data),
    getUser: (token: string): Promise<any> =>
      client.get(`/accounts/users/${token}/`),
    updateUser: (data: any): Promise<any> =>
      client.put('/accounts/users/me/', data),
    updateUserProfile: (data: any): Promise<any> =>
      client.patch('/accounts/users/current/profile/', data),
    resendLogin: (): Promise<any> => client.post('/accounts/users/resend/'),
  },
};

const banking = {
  bankAccount: {
    updateBankAccount: (data: any): Promise<any> =>
      client.patch('/banking/bankaccount/current/', data),
  },
};

const billing = {
  invoices: {
    getInvoices: (): Promise<Invoice[]> => client.get('/billing/invoices/'),
  },
};

const certificates = {
  bbbeeAffidavit: {
    updateBbbeeAffidavit: (data: any): Promise<any> =>
      client.patch('/certificates/bee/current/', data),
  },
};

const cipc = {
  annualReturns: {
    getAnnualReturns: (): Promise<AnnualReturn[]> =>
      client.get('/companys/annualreturns/'),
    updateAnnualReturn: (id: number, payload: AnnualReturn[]): Promise<any> =>
      client.patch(`/companys/annualreturns/${id}/`, payload),
    updateAnnualReturnState: (id: number, state: string): Promise<any> =>
      client.patch(`/companys/annualreturns/${id}/update-state/`, { state }),
  },
  addressChange: {
    getAddressChange: (): Promise<AddressChange> =>
      client.get('/cipc/addresschange/current/'),
    updateAddressChange: (
      data: AddressChangeFormValues
    ): Promise<AddressChange> =>
      client.patch('/cipc/addresschange/current/', data),
  },
  beneficialOwnership: {
    getBeneficialOwnership: (): Promise<any> =>
      client.get('/cipc/beneficialownership/current/'),
    updateBeneficialOwnership: (data: any): Promise<any> =>
      client.patch('/cipc/beneficialownership/current/', data),
    updateBeneficialOwnershipState: (data: any): Promise<any> =>
      client.patch('/cipc/beneficialownership/current/update-state/', data),
  },
  deregistration: {
    updateDeregistration: (): Promise<any> =>
      client.patch('/cipc/deregistration/current/'),
    updateDeregistrationState: (data: any): Promise<any> =>
      client.patch('/cipc/deregistration/current/update-state/', data),
  },
  directorChange: {
    updateDirectorChange: (data: any): Promise<any> =>
      client.patch('/cipc/directorchange/current/', data),
  },
  incorporationDocuments: {
    updateIncorporationDocuments: (): Promise<any> =>
      client.patch('/cipc/incorporationdoc/current/'),
  },
  nameChange: {
    updateNameChange: (data: { name: string }): Promise<any> =>
      client.patch('/cipc/namechange/current/', data),
  },
  nameReservation: {
    getNameReservation: (): Promise<any> =>
      client.get('/cipc/namereservation/current/'),
    updateNameReservation: (data: any): Promise<any> =>
      client.patch('/cipc/namereservation/current/', data),
    resubmitNameReservation: (pk: number, data: any): Promise<any> =>
      client.patch(`/cipc/namereservation/${pk}/resubmit/`, data),
  },
  reinstatement: {
    updateReinstatement: (): Promise<any> =>
      client.patch('/cipc/reinstatement/current/'),
    updateReinstatementState: (data: any): Promise<any> =>
      client.patch('/cipc/reinstatement/current/update-state/', data),
  },
  trademark: {
    updateTrademark: (data: any): Promise<any> =>
      client.patch('/cipc/trademark/current/', data),
  },
};

const cidb = {
  cidbRegistration: {
    getCidbRegistration: (): Promise<any> => client.get('/cidb/cidb/current/'),
    updateCidbRegistration: (data: any): Promise<any> =>
      client.patch('/cidb/cidb/current/', data),
    updateCidbRegistrationState: (data: any): Promise<any> =>
      client.patch('/cidb/cidb/current/update-state/', data),
  },
};

const coid = {
  coidRegistration: {
    updateCoidRegistration: (data: any): Promise<any> =>
      client.patch('/labour/coid/current/', data),
  },
  coidRenewal: {
    getCoidRenewal: (): Promise<CoidRenewal> =>
      client.get('/labour/coidrenewal/current/'),
    updateCoidRenewal: (data: any): Promise<CoidRenewal> =>
      client.patch('/labour/coidrenewal/current/', data),
  },
};

const companys = {
  companys: {
    createCompany: (): Promise<any> => client.post('/companys/companys/'),
    getCompanys: (): Promise<any> => client.get('/companys/companys/'),
    getCompany: (pk: number): Promise<any> =>
      client.get(`/companys/companys/${pk}`),
    setCurrentCompany: (pk: number): Promise<any> =>
      client.post(`/companys/companys/${pk}/set-current/`),
    updateCompany: (pk: number, data: any): Promise<any> =>
      client.patch(`/companys/companys/${pk}/`, data),
    submitCompany: (pk: number, data: any): Promise<any> =>
      client.post(`/companys/companys/${pk}/submit/`, data),
    getDirectors: (pk: number): Promise<any> =>
      client.get(`/companys/companys/${pk}/directors/`),
    createDirector: (pk: number, data: any): Promise<any> =>
      client.post(`/companys/companys/${pk}/add-director/`, data),
    createDirectors: (pk: number, data: any): Promise<any> =>
      client.post(`/companys/companys/${pk}/add-directors/`, data),
  },
  membership: {
    getMembership: (inviteId: string): Promise<any> =>
      client.get(`/companys/membership/${inviteId}/`),
    removeMembership: (inviteId: string): Promise<any> =>
      client.delete(`/companys/membership/${inviteId}/force_remove/`),
    acceptMembership: (inviteId: string): Promise<any> =>
      client.patch(`/companys/membership/${inviteId}/sign-accept//`),
    updateKyc: (inviteId: string, data: any): Promise<any> =>
      client.patch(`/companys/membership/${inviteId}/kyc/`, data),
    resendInviteLink: (inviteId: string): Promise<any> =>
      client.post(`/companys/membership/${inviteId}/resend_invite_link/`),
    updateEmail: (inviteId: string, data: any): Promise<any> =>
      client.post(`/companys/membership/${inviteId}/update_email/`, data),
  },
};

interface ComplianceTasksParams {
  slugs?: string[];
  states?: string[];
}

const compliance = {
  complianceTasks: {
    getComplianceTasks: ({
      slugs,
      states,
    }: ComplianceTasksParams = {}): Promise<any> => {
      const params = new URLSearchParams();

      if (states?.length) {
        params.append('state', states.join('|'));
      }

      if (slugs?.length) {
        params.append('slug', slugs.join('|'));
      }

      const queryString = params.toString();
      return client.get(
        queryString
          ? `/compliance/compliance-tasks/?${queryString}`
          : '/compliance/compliance-tasks/'
      );
    },
    updateComplianceTask: (pk: number, data: any): Promise<any> =>
      client.patch(`/compliance/compliance-tasks/${pk}/`, data),
  },
};

const csd = {
  csdRegistration: {
    updateCsdRegistration: (): Promise<any> =>
      client.patch('/csd/csd/current/'),
  },
};

const dashboard = {
  cards: {
    getCards: (): Promise<Card[]> => client.get('/dashboard/cards/'),
    archiveCard: (pk: number): Promise<any> =>
      client.patch(`/dashboard/cards/${pk}/archive/`),
  },
};

const documents = {
  company: {
    getCompanyDocuments: (companyId: number): Promise<any> =>
      client.get(`/documents/company/${companyId}/documents/`),
  },
};

const invoicing = {
  invoiceGenerator: {
    createInvoice: (data: any): Promise<InvoiceApiResponse> =>
      client.post('/invoicing/invoicegenerator/', data),
    sendInvoiceByEmail: (pk: number, data: { email: string }): Promise<any> =>
      client.post(`/invoicing/invoicegenerator/${pk}/send-by-email/`, {
        to_addr: data.email,
      }),
  },
};

const payments = {
  yoco: {
    createYocoPayment: (data: any): Promise<any> =>
      client.post('/payments/yoco/', data),
  },
};

const sars = {
  importExportLicense: {
    getImportExportLicence: (): Promise<any> =>
      client.get('/sars/importexportlicense/current/'),
    updateImportExportLicence: (data: any): Promise<any> =>
      client.patch('/sars/importexportlicense/current/', data),
    updateImportExportLicenceState: (data: any): Promise<any> =>
      client.patch('/sars/importexportlicense/current/update-state/', data),
  },
  taxClearance: {
    getTaxClearance: (): Promise<TaxClearanceType> =>
      client.get('/sars/taxclearance/current/'),
    updateTaxClearance: (data: any): Promise<any> =>
      client.patch('/sars/taxclearance/current/', data),
  },
  payeRegistration: {
    getPayeRegistration: (): Promise<any> =>
      client.get('/sars/payeregistration/current/'),
    updatePayeRegistration: (data: any): Promise<any> =>
      client.patch('/sars/payeregistration/current/', data),
    updatePayeRegistrationState: (data: any): Promise<any> =>
      client.patch('/sars/payeregistration/current/update-state/', data),
  },
  sarsRep: {
    getSarsRep: (): Promise<any> => client.get('/sars/sarsrep/current/'),
    updateSarsRep: (data: any): Promise<any> =>
      client.patch('/sars/sarsrep/current/', data),
    updateSarsRepState: (data: any): Promise<any> =>
      client.patch('/sars/sarsrep/current/update-state/', data),
  },
  registeredRep: {
    getRegisteredRep: (): Promise<RegisteredRep> =>
      client.get('/sars/registeredrep/current/'),
    queueRegisteredRep: (pk: number): Promise<any> =>
      client.patch(`/sars/registeredrep/${pk}/queue/`),
  },
  taxReturn: {
    getTaxReturn: (): Promise<any> => client.get('/sars/taxreturn/current/'),
    getTaxReturnStatus: (): Promise<any> =>
      client.get('/sars/taxreturn/status/'),
    updateTaxReturn: (data: any): Promise<any> =>
      client.patch('/sars/taxreturn/current/', data),
    updateTaxReturnState: (data: any): Promise<any> =>
      client.patch('/sars/taxreturn/current/update-state/', data),
    getTaxReturnPeriod: (pk: number): Promise<any> =>
      client.get(`/sars/taxreturnperiod/${pk}/?missing-bank-statements=true`),
    getTaxReturnPeriodByYear: (year: string): Promise<any> =>
      client.get(`/sars/taxreturnperiod/?year=${year}`),
    updateTaxReturnPeriodState: (
      pk: number,
      data: { state: string }
    ): Promise<any> =>
      client.patch(`/sars/taxreturnperiod/${pk}/update-state/`, data),
  },
  uifRegistration: {
    updateUifRegistration: (data: any): Promise<any> =>
      client.patch('/sars/uifregistration/current/', data),
  },
  vatRegistration: {
    getVatRegistration: (): Promise<any> =>
      client.get('/sars/vatregistration/current/'),
    updateVatRegistration: (data: any): Promise<any> =>
      client.patch('/sars/vatregistration/current/', data),
    updateVatRegistrationState: (data: any): Promise<any> =>
      client.patch('/sars/vatregistration/current/update-state/', data),
  },
  vatReturn: {
    updateVatReturnPeriod: (period: any, data: any): Promise<any> =>
      client.patch(`/sars/vatreturnperiod/${period}/`, data),
    getVatReturnPeriods: (): Promise<any> =>
      client.get('/sars/vatreturnperiod/'),
    getVatReturnPeriodByYear: (year: string): Promise<any> =>
      client.get(`/sars/vatreturnperiod/?year=${year}`),
    getVatReturnSummaryByPeriod: (periodPk: number): Promise<any> =>
      client.get(`/sars/vatreturnperiod/${periodPk}/summary/`),
    getDetailedSummary: (periodPk: number): Promise<any> =>
      client.get(`/sars/vatreturnperiod/${periodPk}/summary-detailed/`),
  },
};

const services = {
  companyServices: {
    getService: (pk: number): Promise<any> =>
      client.get(`/services/companyservices/${pk}/`),
    getServices: (): Promise<any> => client.get('/services/companyservices/'),
    requestService: (pk: Number): Promise<any> =>
      client.post(`/services/companyservices/${pk}/request_service/`),
    getSubscriptionServices: (): Promise<Subscription[]> =>
      client.get(`/services/companyservices/list-subscriptions/`),
  },
};

const shares = {
  shareholding: {
    createShareRegister: (data: any): Promise<any> =>
      client.post('/shares/shareholding/', data),
  },
};

const shop = {
  carts: {
    getCart: (): Promise<any> => client.get('/shop/carts/current/'),
    getCarts: (): Promise<any> => client.get('/shop/carts/'),
    checkoutCart: (): Promise<any> =>
      client.post('/shop/carts/current/checkout/'),
    removeCartItem: (date: any): Promise<any> =>
      client.patch('/shop/carts/current/remove-item/', date),
    clearCart: (): Promise<any> =>
      client.patch('/shop/carts/current/clear-cart/'),
    addServicesToCart: (data: string[]): Promise<any> =>
      client.patch('/shop/carts/current/add-services/', {
        service_slugs: data,
      }),
    addSubscriptionToCart: (data: {
      subscriptionSlug: string;
      addons: string[];
      interval: 'monthly' | 'annually' | '';
    }): Promise<any> =>
      client.patch('/shop/carts/current/add-subscription/', data),
  },
  orders: {
    getOrders: (): Promise<any[]> => client.get('/shop/orders/'),
    getOrder: (pk: number): Promise<Order> => client.get(`/shop/orders/${pk}/`),
    getActiveSubscriptions: (): Promise<ActiveSubscriptionItem[]> =>
      client.get('/shop/subscriptions'),
    getCurrentOrder: (): Promise<any> => client.get('/shop/orders/current'),
    checkPaymentStatus: (): Promise<any> =>
      client.get('/shop/orders/current/get-status/'),
    smsBankDetails: (): Promise<any> =>
      client.post('/shop/orders/current/sms-bank-details/'),
    setMarkAsPaidSeen: (pk: number): Promise<any> =>
      client.patch(`/shop/orders/${pk}/paid-seen/`),
    setMarkAsUnpaid: (pk: number): Promise<any> =>
      client.post(`/shop/orders/${pk}/mark-unpaid/`),
  },
  coupons: {
    applyCoupon: (couponCode: string): Promise<any> =>
      client.post(`/shop/coupons/${couponCode}/apply/`),
  },
};

export const endpoints = {
  accounting,
  accounts,
  banking,
  billing,
  certificates,
  companys,
  compliance,
  cipc,
  cidb,
  coid,
  csd,
  dashboard,
  documents,
  invoicing,
  payments,
  sars,
  services,
  shares,
  shop,
};
