import { Typography, List } from 'antd';
import currency from 'currency.js';
import styled from 'styled-components';
import { Item } from 'modules/accounting/Invoices/types';

const { Text } = Typography;

const Wrapper = styled.div`
  .line-item {
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: auto;
    padding-bottom: 8px;
    grid-template-areas: 'item item item quantity price price  amount amount';

    & .mobile-title {
      display: none;
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: auto;
      grid-template-areas:
        'item item item item item'
        'quantity price price amount amount';

      .mobile-title {
        display: flex;
      }

      .item {
        padding-bottom: 12px;
      }
    }

    & .title {
      justify-self: start;
    }

    & .item {
      grid-area: item;
    }

    & .quantity {
      grid-area: quantity;
    }

    & .price {
      grid-area: price;
      justify-self: center;
    }

    & .amount {
      grid-area: amount;
      justify-self: center;
    }

    & .currency-item {
      justify-self: end;
    }
  }

  @media (max-width: 576px) {
    & .title-bar {
      display: none;
    }
  }
`;

type InvoiceViewItemsListItemProps = {
  item: Item;
  index: number;
};

const InvoiceViewItemsListItem = ({
  item,
  index,
}: InvoiceViewItemsListItemProps) => {
  return (
    <List.Item key={`lineitem-${item.description}-${index}`}>
      <Wrapper>
        <div className="line-item">
          <Text className="item">
            <Text strong className="mobile-title">
              Item
            </Text>
            {item.description}
          </Text>
          <Text className="quantity">
            <Text strong className="mobile-title">
              Quantity
            </Text>
            {item.quantity}
          </Text>
          <Text className="price currency-item">
            <Text strong className="mobile-title">
              Price
            </Text>

            {currency(item.price / 100, {
              symbol: 'R ',
              precision: 2,
            }).format()}
          </Text>
          <Text className="amount currency-item">
            <Text strong className="mobile-title">
              Amount
            </Text>

            {currency((item.quantity * item.price) / 100, {
              symbol: 'R ',
              precision: 2,
            }).format()}
          </Text>
        </div>
      </Wrapper>
    </List.Item>
  );
};

export default InvoiceViewItemsListItem;
