import { ComplianceTaskApiType, ComplianceStateType } from '../types';

/** Gets the current date */
const getCurrentDate = () => new Date();

/** State constants for completed tasks */
const COMPLETE_STATES = [
  'complete',
  'lodged_elsewhere',
  'expired',
] as ComplianceStateType[];

/** Number of days to look ahead for due date threshold */
const DAYS_THRESHOLD = 30;

/**
 * Calculates a future date based on the number of days provided
 * @param days - Number of days to add to current date
 * @returns Date object representing the future threshold date
 */
const getThresholdDate = (days: number) => {
  const date = getCurrentDate();
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * Determines if a compliance task is due within the next 30 days
 * @param task - The compliance task to check
 * @returns boolean indicating if the task is due soon
 */
export const isTaskDueSoon = (task: ComplianceTaskApiType): boolean => {
  const today = getCurrentDate();
  const next30Days = getThresholdDate(DAYS_THRESHOLD);

  return (
    !COMPLETE_STATES.includes(task.state) && // Ignore tasks in any complete state
    new Date(task.dueDate) <= next30Days && // Due date is within threshold
    new Date(task.dueDate) >= today // Due date hasn't passed yet
  );
};
