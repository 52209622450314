export const companyPagePathnames = [
  '/dashboard',
  '/documents',
  '/store',
  '/store/bee',
  '/profile',
  '/tools',
];

export const isCompanyPage = (pathname: string): boolean =>
  companyPagePathnames.includes(pathname);

export const registeredCompanyStates = ['imported', 'incorporated'];

export const isRegisteredCompany = (companyState: string): boolean =>
  registeredCompanyStates.includes(companyState);

export const showComplianceStatus = (pathname: string, companyState: string) =>
  isCompanyPage(pathname) && isRegisteredCompany(companyState);
