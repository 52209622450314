import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import errorHandler from './errorHandler';
import store from 'redux/store';

// create  an axios instance
const BASEURL = process.env.REACT_APP_API_URL;
export const axiosClient = axios.create({
  baseURL: BASEURL,
});

// add interceptor to change api responses to camelCase
axiosClient.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data &&
    response.headers['content-type'] === 'application/json'
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

// add interceptor to change api requests to snake_case
axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  if (newConfig.headers['Content-Type'] === 'multipart/form-data')
    return newConfig;
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }
  return newConfig;
});

// add interceptor to add authentication token to api request
axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  const { token } = store.getState().user; // TODO: should check for token in cookies as fallback
  if (token) {
    newConfig.headers.common.Authorization = `Token ${token}`;
  } else {
    newConfig.headers.common.Authorization = null;
  }

  return newConfig;
});

// https://javascript.plainenglish.io/frontend-api-calls-with-typescript-and-axios-68792d1e63c2

const responseBody = (response: AxiosResponse) => response.data;

export const client = {
  get: (url: string) =>
    axiosClient
      .get(url)
      .then(responseBody)
      .catch((error): Promise<AxiosError> => errorHandler(error)),
  post: (url: string, body = {}) =>
    axiosClient
      .post(url, body)
      .then(responseBody)
      .catch((error): Promise<AxiosError> => errorHandler(error)),
  patch: (url: string, body = {}) =>
    axiosClient
      .patch(url, body)
      .then(responseBody)
      .catch((error): Promise<AxiosError> => errorHandler(error)),
  put: (url: string, body = {}) =>
    axiosClient
      .put(url, body)
      .then(responseBody)
      .catch((error): Promise<AxiosError> => errorHandler(error)),
  delete: (url: string) =>
    axiosClient
      .delete(url)
      .then(responseBody)
      .catch((error): Promise<AxiosError> => errorHandler(error)),
};
