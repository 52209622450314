import { DownloadOutlined } from '@ant-design/icons';
import { Card, Spin, Typography, Button } from 'antd';
import currency from 'currency.js';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomerDetails from '../components/CustomerDetails/CustomerDetails';
import InvoiceViewItemsList from '../components/ViewInvoice/components/InvoiceViewItemsList/InvoiceViewItemsList';
import PaymentDetails from '../components/ViewInvoice/components/PaymentDetails/PaymentDetails';
import { useSharedInvoice } from '../hooks/useSharedInvoice';
import { getStateTag } from '../utils/invoices.utils';
import Page from 'common/components/Page/Page';

const { Text } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 680px;
  margin: 0 auto;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const DetailsCard = styled(Card)`
  .ant-card-body {
    padding: 12px;

    .details-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .details-content {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .dates {
        display: flex;
        flex-direction: column;
      }

      .total {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const SharedInvoiceView = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const { invoice, isLoading, error } = useSharedInvoice({
    invoiceId: invoiceId || '',
  });

  if (error) {
    return (
      <Page title="Invoice" contentWidth="thin">
        <Wrapper>
          <Card>
            <Text type="danger">
              Failed to load invoice. Please try again later.
            </Text>
          </Card>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page title="Invoice" contentWidth="thin">
      <Wrapper>
        <Spin spinning={isLoading}>
          {invoice && (
            <>
              <DetailsCard>
                <div className="details-header flex items-start gap-4">
                  <div className="flex w-full flex-row justify-between text-2xl font-semibold text-gray-600 md:flex-col md:justify-start">
                    #{invoice.invoiceNumber}
                    <div>{getStateTag(invoice?.state)}</div>
                  </div>
                  {invoice.downloadLink && (
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      href={invoice.downloadLink}
                      target="_blank"
                    >
                      Download Invoice
                    </Button>
                  )}
                </div>
                <div className="details-content">
                  <div className="dates">
                    <div className="font-bold text-gray-600">Due by: </div>
                    <div className="">
                      {invoice.dateDue &&
                        format(new Date(invoice.dateDue), 'dd MMM yyyy')}
                    </div>
                  </div>
                  <div className="total">
                    <div className="font-bold text-gray-600">Total Due:</div>
                    <Text className="text-2xl">
                      {invoice.totalIncludingVat
                        ? currency(invoice.totalIncludingVat / 100, {
                            symbol: 'R ',
                            precision: 2,
                          }).format()
                        : 'R 0.00'}
                    </Text>
                  </div>
                </div>
              </DetailsCard>

              <CustomerDetails
                customerName={invoice.customer?.name}
                customerEmail={invoice.customer?.email}
                customerAddress={invoice.customer?.physicalAddress}
                customerVatNumber={invoice.customer?.vatNumber}
              />

              <InvoiceViewItemsList
                lineItems={invoice.lineItems}
                subTotal={invoice.subtotal}
                totalVat={invoice.totalVat}
                totalIncludingVat={invoice.totalIncludingVat}
              />

              <PaymentDetails
                loading={isLoading}
                bankName={invoice.bankName || invoice.bankAccountId?.bankName}
                bankAccountBranchCode={
                  invoice.bankAccountBranchCode ||
                  invoice.bankAccountId?.branchNumber
                }
                bankAccountHolderName={
                  invoice.bankAccountHolderName ||
                  invoice.bankAccountId?.accountHolderName
                }
                bankAccountNumber={
                  invoice.bankAccountNumber ||
                  invoice.bankAccountId?.accountNumber
                }
              />

              {invoice.notes && (
                <Card>
                  <div className="text-lg font-semibold text-gray-500">
                    Notes:
                  </div>
                  <Text>{invoice.notes}</Text>
                </Card>
              )}
            </>
          )}
        </Spin>
      </Wrapper>
    </Page>
  );
};

export default SharedInvoiceView;
