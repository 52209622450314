import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import {
  ComplianceTaskType,
  ComplianceStatusType,
  ComplianceStateType,
} from '../types';
import { calculateTasksComplianceStatus } from '../utils/calculateTasksComplianceStatus';
import { enrichComplianceTasks } from '../utils/enrichComplianceTasks';
import adminApi from 'common/api';

type ComplianceError = {
  message: string;
  originalError: unknown;
};

type UseComplianceTasksReturn = {
  fetchComplianceTasks: () => Promise<ComplianceTaskType[]>;
  updateComplianceTask: (
    id: number,
    payload: { state: ComplianceStateType }
  ) => Promise<void>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  complianceTasks: ComplianceTaskType[];
  complianceStatus: ComplianceStatusType;
  error: ComplianceError | null;
};

const useComplianceTasks = (): UseComplianceTasksReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ComplianceError | null>(null);
  const [complianceTasks, setComplianceTasks] = useState<ComplianceTaskType[]>(
    []
  );
  const [complianceStatus, setComplianceStatus] =
    useState<ComplianceStatusType>('unknown');

  const handleError = useCallback((e: unknown, context: string) => {
    const errorMessage = `Error ${context} - ${e}`;
    const newError: ComplianceError = {
      message: errorMessage,
      originalError: e,
    };
    setError(newError);
    throw newError;
  }, []);

  const fetchComplianceTasks = useCallback(async () => {
    try {
      setLoading(true);
      const response =
        await adminApi.compliance.complianceTasks.getComplianceTasks({
          slugs: [
            // 'vatreturn',
            'taxreturn',
            'shareregister',
            'annualreturn',
            'beneficialownership',
          ],
        });
      const enrichedTasks = enrichComplianceTasks(response);
      setComplianceTasks(enrichedTasks);
      setComplianceStatus(calculateTasksComplianceStatus(enrichedTasks));
      return response;
    } catch (e) {
      handleError(e, 'fetching compliance tasks');
      return [];
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const updateComplianceTask = useCallback(
    async (id: number, payload: { state: ComplianceStateType }) => {
      try {
        setLoading(true);
        await adminApi.compliance.complianceTasks.updateComplianceTask(
          id,
          payload
        );
        await fetchComplianceTasks();
      } catch (e) {
        handleError(e, 'updating compliance task');
      } finally {
        setLoading(false);
      }
    },
    [fetchComplianceTasks, handleError]
  );

  useEffect(() => {
    fetchComplianceTasks();
  }, [fetchComplianceTasks]);

  useEffect(() => {
    setComplianceStatus(calculateTasksComplianceStatus(complianceTasks));
  }, [complianceTasks]);

  return {
    fetchComplianceTasks,
    updateComplianceTask,
    loading,
    error,
    setLoading,
    complianceTasks,
    complianceStatus,
  };
};

export default useComplianceTasks;
