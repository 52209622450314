import { createElement, FC } from 'react';

import { Button } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { COMPLIANCE_CONFIG } from '../../config';
import { ComplianceStatusType } from 'modules/compliance/types';

type Status = ComplianceStatusType;

export const CompanyComplianceBanner = ({ status }: { status: Status }) => {
  const history = useHistory();
  const config = COMPLIANCE_CONFIG.status[status];
  const handleClick = () => {
    history.push('/compliance');
  };

  return (
    <div
      className={classNames(
        'flex flex-col space-y-4 rounded border px-4 py-4 lg:flex-row lg:space-y-0 lg:px-6',
        config.colors.background,
        config.colors.border
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
      onClick={handleClick}
    >
      <div className="flex items-start space-x-4">
        <div className="w-8">
          {createElement(config.icon as FC<{ className: string }>, {
            className: classNames('w-8 h-8', config.colors.icon),
          })}
        </div>
        <div className="">
          <h2 className={classNames('text-sm font-bold', config.colors.label)}>
            {config.label}
          </h2>
          <p className={classNames('text-sm', config.colors.description)}>
            {config.description}
          </p>
        </div>
      </div>

      <div className="flex w-full lg:ml-auto lg:w-auto lg:items-center">
        <Button
          onClick={handleClick}
          className={classNames(
            'w-full border-none lg:w-auto',
            config.colors.button
          )}
        >
          {config.buttonLabel}
        </Button>
      </div>
    </div>
  );
};
