import { ComponentType } from 'react';
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid';
import { ComplianceSlugType, ComplianceCategoryType } from './types';

interface ComplianceBadgeColors {
  bg: string;
  text: string;
}

interface ComplianceStatusColors {
  background: string;
  border: string;
  icon: string;
  label: string;
  description: string;
  updated: string;
  button: string;
  badge: ComplianceBadgeColors;
  title: {
    bg: string;
  };
}

interface ComplianceStatus {
  colors: ComplianceStatusColors;
  icon: ComponentType;
  label: string;
  description: string;
  buttonLabel: string;
  badgeLabel: string;
}

interface ComplianceCategory {
  label: string;
  children: Array<{
    key: ComplianceSlugType;
    label: string;
    emptyMessage?: string;
  }>;
}

interface ComplianceConfig {
  status: Record<
    'nonCompliant' | 'dueSoon' | 'compliant' | 'unknown',
    ComplianceStatus
  >;
  links: Array<{
    slug: ComplianceSlugType;
    startLink: string;
    continueLink: string;
  }>;
  categories: Record<ComplianceCategoryType, ComplianceCategory>;
}

export const COMPLIANCE_CONFIG: ComplianceConfig = {
  status: {
    nonCompliant: {
      colors: {
        background: 'bg-red-50',
        border: 'border-red-100',
        icon: 'text-red-500',
        label: 'text-red-800',
        description: 'text-red-700',
        updated: 'text-red-600',
        button: 'bg-red-500 text-white hover:bg-red-400',
        badge: {
          bg: 'bg-red-100',
          text: 'text-red-800',
        },
        title: {
          bg: 'bg-red-500',
        },
      },
      icon: ShieldExclamationIcon,
      label: 'Company is non-compliant',
      description:
        'Your company has overdue compliance tasks. Fix issues now to avoid penalties and deregistration.',
      buttonLabel: 'Fix compliance issues',
      badgeLabel: 'Non-compliant',
    },
    dueSoon: {
      colors: {
        background: 'bg-amber-50',
        border: 'border-amber-100',
        icon: 'text-amber-500',
        label: 'text-amber-800',
        description: 'text-amber-700',
        updated: 'text-amber-600',
        button: 'bg-amber-500 text-white hover:bg-amber-400',
        badge: {
          bg: 'bg-amber-100',
          text: 'text-amber-800',
        },
        title: {
          bg: 'bg-amber-500',
        },
      },
      icon: ClockIcon,
      label: 'Company has compliance tasks due soon',
      description:
        'Your company has compliance tasks coming up. Complete them now to stay compliant.',
      buttonLabel: 'View upcoming tasks',
      badgeLabel: 'Due soon',
    },
    compliant: {
      colors: {
        background: 'bg-green-50',
        border: 'border-green-100',
        icon: 'text-green-500',
        label: 'text-green-800',
        description: 'text-green-700',
        updated: 'text-green-600',
        button: 'bg-green-500 text-white hover:bg-green-400',
        badge: {
          bg: 'bg-green-100',
          text: 'text-green-800',
        },
        title: {
          bg: 'bg-green-500',
        },
      },
      icon: ShieldCheckIcon,
      label: 'Company is compliant',
      description:
        'No compliance tasks are due. Your company is fully compliant.',
      buttonLabel: 'View compliance',
      badgeLabel: 'Compliant',
    },
    unknown: {
      colors: {
        background: 'bg-gray-50',
        border: 'border-gray-100',
        icon: 'text-gray-500',
        label: 'text-gray-800',
        description: 'text-gray-700',
        updated: 'text-gray-600',
        button: 'bg-gray-500 text-white hover:bg-gray-400',
        badge: {
          bg: 'bg-gray-100',
          text: 'text-gray-800',
        },
        title: {
          bg: 'bg-gray-500',
        },
      },
      icon: QuestionMarkCircleIcon,
      label: 'Company compliance status is unknown',
      description:
        'Your company compliance status is unknown. Fix connection issues to check compliance status.',
      buttonLabel: 'Fix connection issues',
      badgeLabel: 'Unknown',
    },
  },
  categories: {
    cipc: {
      label: 'CIPC',
      children: [
        {
          key: 'annualreturn',
          label: 'Annual Returns',
          emptyMessage: 'No annual returns due',
        },
        {
          key: 'beneficialownership',
          label: 'Beneficial Ownership',
          emptyMessage: 'No beneficial ownership due',
        },
      ],
    },
    sars: {
      label: 'SARS',
      children: [
        {
          key: 'taxreturn',
          label: 'Tax Returns',
          emptyMessage: 'No tax returns due',
        },
        // {
        //   key: 'vatreturn',
        //   label: 'VAT Returns',
        //   emptyMessage: 'No vat returns due',
        // },
      ],
    },
    general: {
      label: 'General',
      children: [
        {
          key: 'shareregister',
          label: 'Share Register',
          emptyMessage: 'No share register due',
        },
      ],
    },
  },
  links: [
    {
      slug: 'shareregister',
      startLink: '/store/shareregister',
      continueLink: '/share-certificates',
    },
    {
      slug: 'taxreturn',
      startLink: '/store/taxreturn',
      continueLink: '/tax-returns-status',
    },
    {
      slug: 'vatreturn',
      startLink: '/vat-returns/periods',
      continueLink: '/vat-returns/periods',
    },
    {
      slug: 'annualreturn',
      startLink: '/annual-returns',
      continueLink: '/annual-returns',
    },
    {
      slug: 'beneficialownership',
      startLink: '/store/beneficialownership',
      continueLink: '/beneficial-ownership',
    },
  ],
};
